:root{
--beforeleft: -184px;
--beforewidth: 184px;
}
.badge-circle {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 0.875rem;
}
.badge-rounded {
  border-radius: 40px;
}

/*******************
Rounds
*******************/

.round {
  line-height: 48px;
  color: $white;
  width: 50px;
  height: 50px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: $blue;
  line-height: 52px;
  img {
    border-radius: 100%;
  }
  &.round-info {
    background: $blue;
  }

  &.round-warning {
    background: $warning;
  }

  &.round-danger {
    background: $danger;
  }

  &.round-success {
    background: $success;
  }

  &.round-primary {
    background: $primary;
  }
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}


table {
  &.filterColoumns {
    tbody {
      tr {
        td {
          &:nth-child(3) {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
            -webkit-box-orient: vertical;
            border:none;
          }
        }
      }
    }
  }
  }
  .rdw-editor-main {        
    height: 200px !important;
    border: 1px solid;
    padding:0 15px;
    margin-bottom: 15px;
    overflow: hidden !important;
    border:1px solid rgba(0,0,0,.2);

  }
  
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.margin-t10 {
  margin-top: 10px;
}

.margin-t25 {
  margin-top: 25px;
}

.light-logo {
  height: 60px;
  width: 74px;
}
.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}

.removeoptions{
  float: right;
  padding: 5px;
  border-radius: 500px;
  height: 32px;
  width: 31px;
  background: red;
  color: black;
  margin-top: -55px;
  margin-right: -10px;
}

.removeoptionsImage{
  float: right;
  padding: 5px;
  border-radius: 500px;
  height: 32px;
  width: 31px;
  background: red;
  color: black;
  margin-top: -25px;
  margin-left: -10px;
}

.dropable  {  background: gainsboro;
  padding: 5px;
  border-radius: 6px;
  }

.downArrow{
  border-bottom : 2px dotted black;
}

#handleaddedBox{
  position: relative;
}
#imageHandler{
  //text-align: center;
}

.chat-box {
  overflow: auto; }

.chat-list {
  margin: 0px;
  padding: 0px;
  overflow: auto; }
  .chat-list .chat-item {
    list-style: none;
    margin-top: 30px; }
    .chat-list .chat-item .chat-img {
      display: inline-block;
      width: 45px;
      vertical-align: top; }
      .chat-list .chat-item .chat-img img {
        width: 45px;
        border-radius: 100%; }
    .chat-list .chat-item .chat-content {
      width: calc(100% - 50px);
      display: inline-block;
      padding-left: 15px; }
      .chat-list .chat-item .chat-content .box {
        display: inline-block;
        padding: 10px;
        margin-bottom: 3px;
        color: #263238;
        background: #f6f9fc; }
    .chat-list .chat-item .chat-time {
      display: block;
      font-size: 10px;
      color: #525f7f;
      margin: 5px 0 15px 65px; }
    .chat-list .chat-item.odd .chat-content {
      text-align: right;
      width: calc(100% - 0px); }
    .chat-list .chat-item.odd .chat-time {
      text-align: right; }
    .chat-list .chat-item.odd .box {
      clear: both;
      color: #fff;
      background: #1e88e5 !important; }
    .chat-list .chat-item.odd + .odd {
      margin-top: 0px; }
    .chat-list .chat-item.reverse {
      text-align: right; }
      .chat-list .chat-item.reverse .chat-time {
        text-align: left; }
      .chat-list .chat-item.reverse .chat-content {
        padding-left: 0px;
        padding-right: 15px; }

  .mailbox .drop-title {
  font-weight: 500;
  padding: 11px 20px 15px;
  border-radius: 2px 2px 0 0;
  position: relative; }
  .mailbox .drop-title:after {
    content: '';
    position: absolute;
    /*background: url(../../assets/images/background/img5.png) no-repeat;*/
    opacity: 0.2;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-size: cover; }

.mailbox .nav-link {
  border-top: 1px solid #dee2e6;
  padding-top: 15px; }

.mailbox .message-center {
  overflow: auto;
  position: relative; }
  .mailbox .message-center .message-item {
    border-bottom: 1px solid #dee2e6 !important;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 9px 15px;
    cursor: pointer; }
    .mailbox .message-center .message-item:hover, .mailbox .message-center .message-item.active {
      background: #f6f9fc; }
    .mailbox .message-center .message-item:last-child {
      border: 0; }
    .mailbox .message-center .message-item .message-title {
      color: #455a64; }
    .mailbox .message-center .message-item .user-img {
      width: 40px;
      position: relative; }
      .mailbox .message-center .message-item .user-img img {
        width: 100%; }
      .mailbox .message-center .message-item .user-img .profile-status {
        border: 2px solid #fff;
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        left: 30px;
        position: absolute;
        top: 1px;
        width: 10px; }
      .mailbox .message-center .message-item .user-img .online {
        background: #22abbd; }
      .mailbox .message-center .message-item .user-img .busy {
        background: #fc4b6c; }
      .mailbox .message-center .message-item .user-img .away {
        background: #ffb22b; }
      .mailbox .message-center .message-item .user-img .offline {
        background: #ffb22b; }
    .mailbox .message-center .message-item .mail-contnet {
      width: 85%;
      vertical-align: middle;
      padding-left: 15px; }
      .mailbox .message-center .message-item .mail-contnet .message-title {
        margin: 5px 0px 0; }
      .mailbox .message-center .message-item .mail-contnet .mail-desc,
      .mailbox .message-center .message-item .mail-contnet .time {
        font-size: 12px;
        display: block;
        margin: 1px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #8898aa;
        white-space: nowrap; }

        .app-drawer {
          border: 1px solid #dee2e6;
        }
        .mailbox .message-center .message-item .mail-contnet .message-title {
          color: green;
          margin: 5px 0px 0;
      }

      #main-wrapper[data-layout='vertical'] .topbar .navbar-collapse[data-navbarbg='skin4'], #main-wrapper[data-layout='vertical'] .topbar[data-navbarbg='skin4'], #main-wrapper[data-layout='horizontal'] .topbar .navbar-collapse[data-navbarbg='skin4'], #main-wrapper[data-layout='horizontal'] .topbar[data-navbarbg='skin4'], #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin4'], #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin4'] {
        background: #0d8448 !important;
      }

      // #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul {
      //   background:#f8ac08 !important;
      // }

      .app-drawer.chat-room {
        background: #fff;
      }

      .app-drawer.chat-room .left-part {        
        width: auto;
        position: static;
        border:none;
      }

      .app-drawer.chat-room .right-part {
        margin-left:0;
      }

      .cl-search {
        background: #0d8448;
      }

      .cl-search .card-title {
        color:#fff;
      }
      .chat-room .message-item .user-img { background: #f1c40f; color:#000; font-size: 24px; font-weight: bold; display: flex; align-items: center; justify-content: center; border-radius: 100%; width: 40px; height: 40px; line-height: 1.2;}
      .chat-room .message-item:hover .user-img, .chat-room .message-item.active .user-img {
        background: #000;
        color:#f1c40f;

      }
      .chat-room .mailbox .message-center .message-item .mail-contnet .message-title {
        color:#000;
        margin:0

      }
      .chat-room .mailbox .message-center .message-item:hover .mail-contnet .message-title, .chat-room .mailbox .message-center .message-item.active .mail-contnet .message-title {
       font-weight: bold;        

      }
      .chat-room .mailbox .message-center .message-item:hover, .chat-room .mailbox .message-center .message-item.active {
        background: #f1c40f;
      }
      .chat-room .mailbox .message-center .message-item {
        position: relative;
      }
      .chat-room .mailbox .message-center .message-item:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 10px;
        left: -20px;
        border: 10px solid;
        border-color: transparent #ffffff transparent transparent;
    }

    .user-title { background: #f1c40f; font-size: 20px; border-bottom:1px solid #000; display: flex; align-items: center;}
    .user-title strong { color:#000; font-weight: bold;}
    .user-title .user-img { background: #000; color:#f1c40f; font-size: 24px; font-weight: bold; display: flex; align-items: center; justify-content: center; border-radius: 100%; width: 40px; height: 40px; line-height: 1.2; margin-right:15px;}
      
    .chat-room .right-part {
      height: auto;
      border-left:1px solid #dee2e6;
    }

    .chat-room-m-h {
      height: 46vh;
      overflow: auto;
  }
  b, strong {
    font-weight: bolder;
    color: green;
  }
  .chat-list .chat-item .box {
    border-radius: 4px;
    display: inline-block;
    padding: 10px;
    margin-bottom: 3px;
    color: #263238;
    background: #f6f9fc;
}
// table homework
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  /* position: absolute; */
  background-color: antiquewhite;
}

#hide{
  display:none;
}

#show{
  display:block;
}
// input box size homework manager 
input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  size: a5;
  zoom: 2.0;
}

.reportPage .testStatus {
  margin: 0;
  padding: 10px 0 0 0;
}
.reportPage .testStatus li:hover, .reportPage .testStatus li.activeLi {
  width: 100%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px 0 0 5px;
}
.reportPage .testStatus li {
  height: 52px;
  display: block;
  zoom: 1;
  padding: 10px 10px 10px 30px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  line-height: 1.2;
  position: relative;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  width: calc(100% - 25px);
  display: flex;
  align-items: center;
  background: #000;
}
.table th, .table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #cac6c6;
}
.hello {
  width: 100%;
}
.btn.btn-secondary.mb-3.mr-2 {
  display: flex;
  justify-content: center;
  align-self: baseline;
  margin-left: 15px
}



// #main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .sidebar-item.selected>.sidebar-link, #main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6] ul .sidebar-nav ul .sidebar-item.selected>.sidebar-link, #main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .sidebar-item.selected>.sidebar-link, #main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] ul .sidebar-nav ul .sidebar-item.selected>.sidebar-link {
//   background: #1d7c1d  !important;
//   color: #fff;
//   border-radius: 4px;
//   opacity: 1;
// }

.border-bottom.p-1  {
  margin-top: -21px;
  padding: 0.25rem !important;
}
// button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
//   cursor: pointer;
//   margin-top: 17px;
 
// }

.set-pointer input{
  margin-top: -5px;
}
 
.set-pointer::before{
  content: '';
  position: absolute;  
  left: var(--beforeleft);
  background: #000;
  height: 1px;
  //top: 13px;
  width: var(--beforewidth);
}


.set-edit-pointer::before {
  content: '';
  position: absolute;
  left: -223px !important;
  /* left: var(--beforeleft); */
  background: #000;
  height: 1px;
  width: 228px !important;
  /* width: var(--beforewidth); */
}

.set-pointer-right::before{
  content: '';
  position: absolute;  
  left: var(--beforeleft);
  background: #000;
  height: 1px;
  //top: 13px;
  width: var(--beforewidth);
}

.text-center-title{
  text-align: center;
  margin-bottom: 20px;
  margin-left: 200px;
}

.counterBox{
  float: right;
  padding: 5px;
  border-radius: 17px;
  height: 28px;
  width: 25px;
  background: red;
  color: black;
  margin-top: 0px;
  margin-left: -10px;
  content: 5;
}
.mail-contnet .message-title span {
  background: #0d8448;
  padding: 3px 13px;
  border-radius: 100%;
  color: white !important;
}
//@at-root

.chat-panel-content{
  display: flex;
  justify-content: end;
  width: 100%;
  max-width: 400px;

}

.chat-panel-content-student{
  display: flex;
  justify-content: start;
  width: 100%;
  max-width: 400px;
}

#tbl1{
  width: 100%;
      border: solid 1px #eee;
  }
  #tbl1 {
    width: 100%;
    border: solid 1px #eee;
    max-width: 690px;
}
  
  #tbl1 tr{
   border: solid 1px #eee;
   padding: 10px;
  } 
  
  
  #tbl1 td{
   border: solid 1px #eee;
   padding: 10px;
  } 
  #tbl1 th{
    border: solid 1px #eee;
    padding: 10px;
    background-color: #faebd7;
   } 

#tbl1 tr{
  border: solid 1px #eee;
  padding: 10px;
} 


#tbl1 td{
  border: solid 1px #eee;
  padding: 10px;
} 
//

#table-to-xlsss{
  width: 100%;
      border: solid 1px #eee;
  }
  #table-to-xlsss {
    width: 100%;
    border: solid 1px #eee;
    max-width: 690px;
}
  
  #table-to-xlsss tr{
   border: solid 1px #eee;
   padding: 10px;
  } 
  
  
  #table-to-xlsss td{
   border: solid 1px #eee;
   padding: 10px;
  } 
  #table-to-xlsss th{
    border: solid 1px #eee;
    padding: 10px;
    background-color: #faebd7;
   } 

#table-to-xlsss tr{
  border: solid 1px #eee;
  padding: 10px;
} 


#table-to-xlsss td{
  border: solid 1px #eee;
  padding: 10px;
} 

//

  #tbl2{
    width: 100%;
        border: solid 1px #eee;
    }
    
    #tbl2 tr{
     border: solid 1px #eee;
     padding: 10px;
    } 
    
    
    #tbl2 td{
     border: solid 1px #eee;
     padding: 10px;
    } 
  
    #tbl2 tr:nth-child(even) {background-color: #f2f2f2;}
    .weekDays-selector input {
      display: none!important;
    }
    
    .weekDays-selector input[type=checkbox] + label {
      display: inline-block;
      border-radius: 16px;
      background: #dddddd;
      height: 42px;
      width: 90px;
      margin-right: 4px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
    
    .weekDays-selector input[type=checkbox]:checked + label {
      background: #2AD705;
      color: #ffffff;
    }
    #comment-model {
      overflow: inherit !important;
  }