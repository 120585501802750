@each $color, $value in $theme-colors {
  @include bg-gradient-variant('.bg-gradient-#{$color}', $value);
}

// Shape (svg) fill colors
@each $color, $value in $theme-colors {
  .fill-#{$color} {
    fill: $value;
  }

  .stroke-#{$color} {
    stroke: $value;
  }
}
