.app-drawer {
  position: relative;

  .list-group {
    .list-group-item {
      padding: 0px;
      background: transparent;
      border: none;
      cursor: pointer;

      .list-group-item-action {
        padding: 12px 15px;
        display: block;
        color: $body-color;

        .mdi {
          font-size: 18px;
          vertical-align: middle;
          margin-right: 5px;
        }

        &:hover,
        &.active {
          background: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }

  .mail-list,
  .mail-details {
    .empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.listing-table {
  tr:hover {
    cursor: pointer;
  }

  td {
    padding: 16px 9px;
  }
}

.v-middle {
  td,
  th {
    vertical-align: middle;
  }
}

.app-drawer {
  .left-part {
    width: 260px;
  }
  .right-part {
    margin-left: 260px;
  }
}

@include media-breakpoint-down(lg) {
  .app-drawer,
  .chat-list {
    .show-left-part {
      top: 100px;
      left: 95px;
      &.left-part-open {
        left: 355px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .app-drawer,
  .chat-list {
    .left-part {
      left: -200px;
      z-index: -1;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
      transition: all 0.2s ease-in-out;

      &.show-panel {
        z-index: 1;
        left: 0px;
      }
    }

    .right-part {
      margin-left: 0;
      width: 100%;
    }

    .show-left-part {
      position: fixed;
      z-index: 1;
      width: 20px;
      height: 20px;
      text-align: center;
      transition: all 0.2s ease-in-out;
    }
  }
}

@include media-breakpoint-down(sm) {
  .app-drawer,
  .chat-list {
    .show-left-part {
      left: 30px;
      top: 100px;

      &.left-part-open {
        left: 260px;
      }
    }

    .right-part {
      .right-right-part {
        margin-left: 100%;
        z-index: -1;
        position: absolute;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
        transition: all 0.2s ease-in-out;

        &.show-right-right-panel {
          margin-left: 0;
          z-index: 1;
          width: 100%;
          background: $white;
        }

        .hide-right-right-part {
          position: absolute;
          right: 15px;
          top: 15px;
        }
      }

      .right-left-part {
        width: 100%;
      }
    }
  }
}

.with-todos {
  .message-center {
    .message-item {
      position: relative;
      .user-img {
        position: unset;
        width: 0;
        .todo-status {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border: 0;
          height: 100%;
          left: 0;
          width: 3px;
        }
      }
      .mail-contnet {
        padding-left: 0;
        width: 100%;
      }
    }
  }
}
